import React from 'react';
import { useParams } from 'react-router-dom';
import * as colors from '@constants/colors';
import { isEntity } from '@constants/endpoints';

import EntityTypeIcon from '@icons/entity-type-icon';
import WorkflowIcon from '@icons/workflow-icon';

import { Icon } from '@mui';

import './top-bar.scss';

const getIcon = dataType => {
  if (isEntity(dataType)) {
    return <EntityTypeIcon type={dataType} size="1.5rem" color={colors.textColor} />;
  } else
    if (dataType === 'cycle') {
      return <WorkflowIcon />;
    } else
      if (dataType === 'batch-upload-edit') {
        return <Icon>file_upload</Icon>;
      }
  return null;
};

const TopBarTypeIcon = () => {
  const { dataType } = useParams();
  const icon = getIcon(dataType);

  if (icon) {
    return (
      <div styleName="icon">
        {icon}
      </div>
    );
  }

  return null;
};

export default TopBarTypeIcon;
