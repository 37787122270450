import React, { memo } from 'react';

import './drawer-filters.scss';

const DrawerFilters = () => (
  <div styleName="batch-upload-edit-drawer-filters-container">
    TODO
  </div>
);

export default memo(DrawerFilters);
