import React, { memo } from 'react';

import { useParams } from 'react-router-dom';

import DrawerRowItemCard from '@components/batch/batch-upload-edit/drawer-row-item-card';

import { getBatch } from '@utils/batch-utils';

import './drawer-row-list.scss';

const DrawerRowList = () => {
  const { id: fileId } = useParams();
  const batch = getBatch(fileId);
  const rows = [];  // TODO

  if (rows.length === 0) {
    // TODO use Skeleton instead.
    return null;
  }

  return (
    <div styleName="batch-upload-edit-drawer-row-list-container">
      <ul styleName="row-list-body">
        {rows.map(row => <DrawerRowItemCard key={row.id} row={row} />)}
      </ul>
    </div>
  );
};

export default memo(DrawerRowList);
