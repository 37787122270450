import React, { memo } from 'react';
import './content.scss';

const Content = () => (
  <div styleName="batch-upload-edit-content-container">
    TODO
  </div>
);

export default memo(Content);

