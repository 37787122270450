import React, { memo } from 'react';

import DrawerFilters from '@components/batch/batch-upload-edit/drawer-filters';
import DrawerRowList from '@components/batch/batch-upload-edit/drawer-row-list';

import './drawer.scss';

const Drawer = () => (
  <div styleName="batch-upload-edit-drawer-container">
    <DrawerFilters />
    <DrawerRowList />
  </div>
);

export default memo(Drawer);
