import React, { memo } from 'react';

import Drawer from '@components/batch/batch-upload-edit/drawer';
import Content from '@components/batch/batch-upload-edit/content';

import TopBar from '@components/details/top-bar';

import './batch-upload-edit.scss';

const BatchUploadEdit = () => (
  <div styleName="batch-upload-edit-container">
    <TopBar />
    <div styleName="batch-upload-edit-content">
      <Drawer />
      <Content />
    </div>
  </div>
);

export default memo(BatchUploadEdit);
