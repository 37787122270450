import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import DataCard from '@shared/ui-library/card/data-card';
import AttributeLabelContainer from '@shared/ui-library/attribute/attribute-label-container';

const DrawerRowItemCard = ({ row }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { id: fileId } = useParams();

  const selected = useMemo(() => {
    // Build the path for this card to check if it's selected:
    const path = `/batch-upload-edit/${fileId}/${row.id}/`;
    // Get the current location pathname and add a trailing slash if it's not
    // already there, for comparison:
    const url = location.pathname.replace(/\/?$/, '/');
    return url.startsWith(path);
  }, [location.pathname, fileId, row.id]);

  const showEntity = useCallback(() => {
    if (!selected) {
      // TODO
    }
  }, [dispatch, selected, row]);

  return (
    <DataCard
      borderRadius={false}
      onClick={showEntity}
      selected={selected}
      title={row.name}
    >
      <span>
        TODO 1
      </span>
      <AttributeLabelContainer>
        TODO 2
      </AttributeLabelContainer>
    </DataCard>
  );
};

DrawerRowItemCard.propTypes = {
  row: PropTypes.object
};

export default memo(DrawerRowItemCard);
