import { v4 as uuid } from 'uuid';

export const saveBatch = (entity, file, fileContent) => {
  const fileId = uuid();
  const data = { entity, file, fileContent };
  sessionStorage.setItem(fileId, JSON.stringify(data));
  return fileId;
};

export const getBatch = fileId => JSON.parse(sessionStorage.getItem(fileId));
